import React from "react";
import Layout from "../components/layout";
import Hero from "../components/BuildWithUsHero/Hero";
import Services from "../components/Services/Services";
import Content from "../components/Content/Content";
import SEO from "../components/seo";

const BuildWithUs = () => {
    return (
        <Layout>
            <SEO
                title="iQubeLabs | Build With Us"
            />
           <Hero />
           <Services />
           <Content />
        </Layout>
    );
}

export default BuildWithUs;